import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import ErrorBoundary from './util/ErrorBoundary';
import useGaTracker from './GaTracker'


const HomePage = lazy(() => import('./pages/HomePage'));
const ViewMenu = lazy(() => import('./pages/Menu/index'));
const CreateMenu = lazy(() => import('./pages/Menu/NewMenu'));
const EditMenu = lazy(() => import('./pages/Menu/EditMenu'));
const QRCodePage = lazy(() => import('./pages/QRCodePage'));

const App = () => {
  useGaTracker()
  
  return (
    <ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path={routes.HOME} exact component={HomePage} />
          <Route path={routes.CREATE_MENU} component={CreateMenu} />
          <Route path={routes.EDIT_MENU} component={EditMenu} />
          <Route path={routes.VIEW_MENU} component={ViewMenu} />
          <Route path={routes.DOWNLOAD_QRCODE} component={QRCodePage} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
