import React, { createContext, useReducer } from 'react';
import logger from 'use-reducer-logger';

import { IMenu } from '../interfaces/MenuInterface';
import { initialState, menuReducer, MenuAction } from '../reducer/MenuReducer';

const AppContext = createContext<{
  state: IMenu;
  dispatch: React.Dispatch<MenuAction>;
}>({
  state: initialState,
  dispatch: () => null
});

const AppProvider: React.FC = ({ children }) => {
    //const [state, dispatch] = useReducer(menuReducer, initialState);
  
    const [state, dispatch] = useReducer(logger(menuReducer) , initialState);
    
    return (
      <AppContext.Provider value={{state, dispatch}}>
        {children}
      </AppContext.Provider>
    )
}

export { AppContext, AppProvider };