const routes = {
    HOME: '/',
    CREATE_MENU: '/create/new',
    EDIT_MENU: '/create/from/:menuId',
    VIEW_MENU: '/view/:menuId',
    DOWNLOAD_QRCODE: '/download/:menuId'
};

export function getIdRoute(route: any, id: any) {
    return route.replace(":menuId", id);
}

export default routes;