import { IMenu } from '../interfaces/MenuInterface';
import { IItem } from '../interfaces/MenuInterface';

export const initialState: IMenu = {
    restaurantName: '',
    email: '',
    currency: '€',
    categories: [{
        categoryId: 1,
        categoryName: 'Category 1',
        categoryNameInput: false,
        items: []
    }],
    meta: {
        categoryCount: 1,
        activeItem: '',
        deleteCategory: false
    }
}

export type MenuAction =
    |{ type: 'ADD_EMAIL' | 'ADD_CURRENCY' | 'ADD_RESTAURANT_NAME', payload: string}
    |{ type: 'ADD_CATEGORY', payload: {
        categoryId: number,
        categoryName: string,
        categoryNameInput: boolean,
        items: IItem[]
    }}
    | { type: 'SET_ACTIVE_ITEM', payload: string }
    | { type: 'SET_CATEGORY_COUNT' | 'DELETE_CATEGORY', payload: number}
    | { type: 'EDIT_CATEGORY_NAME', payload: {
        id: number
        name: string
    }}
    | {type: 'SET_CATEGORY_NAME_INPUT', payload: {
        id: number
        toggle: boolean
    }}
    |{ type: 'ADD_ITEMS' | 'EDIT_ITEM', payload: {
        id: number,
        item: any
    }}
    |{type: 'DELETE_ITEM', payload: {
        categoryId: number,
        itemId: string
    }}
    |{ type: 'GET_MENU', payload: IMenu }

 
export const menuReducer = (state: IMenu, action: MenuAction): IMenu => {
    switch (action.type) {
        case 'ADD_EMAIL': 
            return {
                ...state,
                email: action.payload
            }
        case 'ADD_CURRENCY': 
            return {
                ...state,
                currency: action.payload
            }
        case 'ADD_RESTAURANT_NAME': 
            return {
                ...state,
                restaurantName: action.payload
            }
        case 'ADD_CATEGORY':
            return {
                ...state,
                categories: [...state.categories, action.payload]
            }
        case 'SET_ACTIVE_ITEM': 
            return {
                ...state,
                meta: {
                    ...state.meta,
                    activeItem: action.payload
                }
            }
        case 'SET_CATEGORY_COUNT':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    categoryCount: action.payload
                }
            }
        case 'EDIT_CATEGORY_NAME': 
            const categoryIndex = state.categories.findIndex(el => el.categoryId === action.payload.id);
            state.categories[categoryIndex] = {
                'categoryId':  action.payload.id,
                'categoryName': action.payload.name,
                'categoryNameInput': true,
                items: state.categories[categoryIndex].items
            }
    
            return {
                ...state,
                meta: {
                    ...state.meta,
                    activeItem: action.payload.name
                }
            }
        case 'SET_CATEGORY_NAME_INPUT': 
            const categoryFoundIndex = state.categories.findIndex(el => el.categoryId === action.payload.id);
            state.categories[categoryFoundIndex] = {
                categoryId: action.payload.id,
                categoryName: state.categories[categoryFoundIndex].categoryName,
                categoryNameInput: action.payload.toggle,
                items: state.categories[categoryFoundIndex].items
            }
           
            return {
                ...state
            }
        case 'DELETE_CATEGORY': 
            return{
                ...state,
                categories: state.categories.filter(el => el.categoryId !== action.payload),
                meta: {
                    ...state.meta,
                    deleteCategory: true
                }
            }
        case 'ADD_ITEMS': 
            const foundIndex = state.categories.findIndex(el => el.categoryId === action.payload.id);
            state.categories[foundIndex] = {
                ...state.categories[foundIndex],
                items: state.categories[foundIndex].items.length>=1 ? [...state.categories[foundIndex].items, action.payload.item] : [action.payload.item]
            }

            return {
                ...state
            }
        case 'EDIT_ITEM': 
            const catfoundIndex = state.categories.findIndex(el => el.categoryId === action.payload.id);
            const itemfoundIndex = state.categories[catfoundIndex].items.findIndex(el => el.itemId === action.payload.item.itemId )
            state.categories[catfoundIndex].items[itemfoundIndex] = action.payload.item
            return {
                ...state
            }
        case 'DELETE_ITEM':
            const catFoundIndex = state.categories.findIndex(el => el.categoryId === action.payload.categoryId);
            
            state.categories[catFoundIndex] = {
                ...state.categories[catFoundIndex],
                items: state.categories[catFoundIndex].items.filter(el => el.itemId !== action.payload.itemId )
            }

            return {
                ...state
            }
        case 'GET_MENU':
            return{
                ...action.payload
            }
        default:
            return state;
    }
}
